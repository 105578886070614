import {EventEmitter, Injectable} from '@angular/core';
import {Base64Service} from './base64.service';
import {LocalStorageService} from './local-storage.service';
import {JwtClaims} from '../models/jwt-claims';

@Injectable()
export class JwtService {

    static jwtChanged: EventEmitter<JwtClaims> = new EventEmitter<JwtClaims>();

    static AccessToken  = 'jwt-access-token';
    static RefreshToken = 'jwt-refresh-token';
    static ClientSkew = 'jwt-client-skew';

    static raw(): string {
        return LocalStorageService.get(JwtService.AccessToken);
    }

    static clear(): void {
        LocalStorageService.destroy(JwtService.AccessToken);
        JwtService.jwtChanged.emit(null);
    }

    static store(jwt: string): void {
        LocalStorageService.set(JwtService.AccessToken, jwt);

        const claims = JwtService.getCustomClaims();
        JwtService.jwtChanged.emit(claims);
    }

    static getRefreshToken(): string {
        return LocalStorageService.get(JwtService.RefreshToken);
    }

    static storeRefreshToken(token: string): void {
        LocalStorageService.set(JwtService.RefreshToken, token);
    }

    static clearRefreshToken() {
        LocalStorageService.destroy(JwtService.RefreshToken);
    }

    static getClientSkew(): number {
        return Number(LocalStorageService.get(JwtService.ClientSkew) || 0);
    }

    static storeClientSkew(token: number): void {
        LocalStorageService.set(JwtService.ClientSkew, token.toString());
    }

    static clearClientSkew() {
        LocalStorageService.destroy(JwtService.ClientSkew);
    }

    static getCustomClaims(): JwtClaims {
        const clockSkew = this.getClientSkew();

        // Retrieve JWT from storage.
        const jwt: string = JwtService.raw();
        if (jwt === null || jwt.length < 1) {
            return null;
        }

        // Valid JWTs have 3 parts.
        //noinspection JSMismatchedCollectionQueryUpdate
        const jwtParts: string[] = jwt.split('.');
        if (jwtParts.length !== 3) {
            JwtService.clear();
            return null;
        }

        // Decode JWT.
        const payload: any = JwtService.decodeToken(jwt);
        let customClaims: any = {};
        if (!payload.hasOwnProperty('clm')) {
            JwtService.clear();
            return null;
        } else {
            customClaims = payload['clm'];
        }

        // Check standard expiry claim.
        if (payload.hasOwnProperty('exp')) {
            const expires: number = payload['exp'];
            const now = Math.round((new Date().getTime()) / 1000) - clockSkew;
            if (expires < now) {
                JwtService.clear();
                return null;
            }
        }

        // Check standard not-before claim.
        if (payload.hasOwnProperty('nbf')) {
            const notBefore: number = payload['nbf'];
            const now = Math.round((new Date().getTime()) / 1000) - clockSkew;
            if (notBefore > now) {
                JwtService.clear();
                return null;
            }
        }

        // Cast payload to claims object and return.
        return new JwtClaims(customClaims);
    }

    static decodeToken(jwt: string) {
        // Decode JWT.
        const jwtParts: string[] = jwt.split('.');

        // Valid JWTs have 3 parts.
        if (jwtParts.length !== 3) {
            JwtService.clear();
            return null;
        }

        // Decode the payload.
        let decodedJwt: string = Base64Service.decode(jwtParts[1]).trim();

        // Trim end of payload after final closing curly brace in case our fallback got triggered.
        // Fixes issue with base64 encode polyfill leaving a null termination character at end.
        if (decodedJwt[decodedJwt.length - 1] !== '}') {
            decodedJwt = decodedJwt.substr(0, decodedJwt.lastIndexOf('}') + 1);
        }

        return JSON.parse(decodedJwt);
    }
}
