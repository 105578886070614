import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title = 'simple990-com';

    constructor(
        private _gtmService: GoogleTagManagerService,
        private _router: Router,
        private _titleService: Title
    ) {
    }

    ngOnInit() {
        /**  */
        this._router.events.pipe(filter(
            (event) => {
                return event instanceof NavigationEnd;
            }
        )).subscribe((event: NavigationEnd) => {
            try {
                const gtmTag = {
                    event: 'pageview',
                    pageName: event.url,
                    pageUrl: event.url,
                    pageTitle: this._titleService.getTitle()
                };
                this._gtmService.pushTag(gtmTag).catch((e) => {});
            } catch (e) {}
        });
    }
}
