<!--[if lt IE 10]>
<div class="ie-outdated">
    It looks like you are using an outdated web browser. Try a different one or update to the latest version for a better experience
</div>
<![endif]-->

<toaster-container></toaster-container>

<div class="animated fadeIn">
    <app-hexagons></app-hexagons>
    <router-outlet></router-outlet>
</div>
