import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {JwtService} from '../services/jwt.service';
import {RoutingService} from '../services/routing.service';
import {Routes} from '../../../environments/vars/routes';
import {AuthService} from '../services/auth.service';

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild {

    constructor(
        private _authService: AuthService,
        private _routingService: RoutingService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAllowed(true);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAllowed(false);
    }

    private async isAllowed(
        redirectOnFailure = false
    ): Promise<boolean> {
        const claims = JwtService.getCustomClaims();
        if (claims !== null) {
            return true;
        }

        const refreshToken = JwtService.getRefreshToken();
        if (refreshToken) {
            try {
                const token = await this._authService.manualRefresh(refreshToken);
                JwtService.storeClientSkew(token.client_skew);
                JwtService.storeRefreshToken(token.refresh_token);
                JwtService.store(token.access_token);

                const updatedClaims = JwtService.getCustomClaims();
                if (updatedClaims !== null) {
                    return true;
                }
            } catch (e) {
                if (redirectOnFailure) {
                    this._routingService.redirectToLogin();
                }
                this._authService.triggerReauthentication();
                return false;
            }

            if (redirectOnFailure) {
                this._routingService.redirectToLogin();
            }
            this._authService.triggerReauthentication();
            return false;
        }

        if (redirectOnFailure) {
            this._routingService.redirectToLogin();
        }
        this._authService.triggerReauthentication();
        return false;
    }
}
