import {Injectable} from '@angular/core';
import {NavigationExtras, Router, UrlTree} from '@angular/router';
import {Location} from '@angular/common';
import {Routes} from '../../../environments/vars/routes';

@Injectable()
export class RoutingService {

    constructor(
        private _location: Location,
        private _router: Router
    ) {}

    getCurrentPath() {
        return this._location.path();
    }

    redirectToLogin() {
        // Use view that fired request and redirect URI when redirecting to login page.
        const uri = this.getCurrentPath();
        const loginView = [
            Routes.UI.Login,
            {uri: encodeURIComponent(uri)}
        ];

        //noinspection JSIgnoredPromiseFromCall
        this._router.navigate(loginView);
    }

    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this._router.navigate(commands, extras);
    }

    navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
        return this._router.navigateByUrl(url, extras);
    }
}
