import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ApiHttpInterceptor} from './shared/api-http-interceptor';
import {ApiRefreshTokenInterceptor} from './shared/api-refresh-token-interceptor';
import {AuthService} from './shared/services/auth.service';
import {RoutingService} from './shared/services/routing.service';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {NgxJsonapiModule} from 'ngx-jsonapi';
import {AdminGuard} from './shared/guards/admin.guard';
import {LoginGuard} from './shared/guards/login.guard';
import {PrunerGuard} from './shared/guards/pruner.guard';
import {HexagonsModule} from './shared/components/hexagons/hexagons.module';
// import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import * as Sentry from '@sentry/angular';
import {Form990ezFormService} from './shared/services/api/form990ez-form.service';
import {Router} from '@angular/router';
import {SentryErrorHandler} from './shared/exception/sentry-error-handler';
import {SupportGuard} from './shared/guards/support.guard';
import {EmployeeGuard} from './shared/guards/employee.guard';

export function ApiHttpInterceptorFactory(
    _authService: AuthService,
    _routingService: RoutingService
) {
    return new ApiHttpInterceptor(_authService, _routingService);
}

export function ApiRefreshTokenInterceptorFactory(
    _authService: AuthService,
    _routingService: RoutingService,
) {
    return new ApiRefreshTokenInterceptor(_authService, _routingService, new ApiHttpInterceptor(_authService, _routingService));
}

export const interceptorProviders = [{
    provide: HTTP_INTERCEPTORS,
    useFactory: ApiHttpInterceptorFactory,
    deps: [AuthService, RoutingService],
    multi: true
}, {
    provide: HTTP_INTERCEPTORS,
    useFactory: ApiRefreshTokenInterceptorFactory,
    deps: [AuthService, RoutingService],
    multi: true
}];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HexagonsModule,
        // NgxStripeModule.forRoot(environment.stripe.publicKey),
        HttpClientModule,
        AppRoutingModule,
        ToasterModule.forRoot(),
        NgxJsonapiModule.forRoot({
            url: environment.api + '/v2/'
        })
    ],
    providers: [
        Title,
        AdminGuard,
        LoginGuard,
        PrunerGuard,
        SupportGuard,
        EmployeeGuard,
        PrunerGuard,
        AuthService,
        Form990ezFormService,
        RoutingService,
        ToasterService,
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {provide: ErrorHandler, useValue: new SentryErrorHandler({
            showDialog: true,
            logErrors: true
        })},
        {provide: 'googleTagManagerId', useValue: environment.google.gtm},
        interceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
