<div class="hexagon-parent">
    <div class="hexagons-block">
        
        <div class="row row-1"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darker"></div>
        </div>

        <div class="row row-2"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darker"></div>
            <div class="hexagon hexagon-4 darker"></div>
            <div class="hexagon hexagon-5 darker"></div>
        </div>

        <div class="row row-3"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darkest"></div>
            <div class="hexagon hexagon-4 darker"></div>
            <div class="hexagon hexagon-5 darker"></div>
            <div class="hexagon hexagon-6 darker"></div>
            <div class="hexagon hexagon-7 darker"></div>
            <div class="hexagon hexagon-8 darker"></div>
            <div class="hexagon hexagon-9 darker"></div>
            <div class="hexagon hexagon-10 darker"></div>
            <div class="hexagon hexagon-11 light"></div>
            <div class="hexagon hexagon-12 light"></div>
            <div class="hexagon hexagon-13 light"></div>
            <div class="hexagon hexagon-14 light"></div>
            <div class="hexagon hexagon-15 light"></div>
        </div>

        <div class="row row-4"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darker"></div>
            <div class="hexagon hexagon-4 darker"></div>
            <div class="hexagon hexagon-5 darker"></div>
            <div class="hexagon hexagon-6 darker"></div>
            <div class="hexagon hexagon-7 darker"></div>
            <div class="hexagon hexagon-8 darker"></div>
            <div class="hexagon hexagon-9 darker"></div>
            <div class="hexagon hexagon-10 darker"></div>
            <div class="hexagon hexagon-11 darker"></div>
            <div class="hexagon hexagon-12 light"></div>
            <div class="hexagon hexagon-13 light"></div>
            <div class="hexagon hexagon-14 light"></div>
            <div class="hexagon hexagon-15 light"></div>
            <div class="hexagon hexagon-16 light"></div>
            <div class="hexagon hexagon-17 light"></div>
            <div class="hexagon hexagon-18 light"></div>
            <div class="hexagon hexagon-19 light"></div>
            <div class="hexagon hexagon-20 light"></div>
        </div>

        <div class="row row-5"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darkest"></div>
            <div class="hexagon hexagon-4 darker"></div>
            <div class="hexagon hexagon-5 darker"></div>
            <div class="hexagon hexagon-6 darker"></div>
            <div class="hexagon hexagon-7 darker"></div>
            <div class="hexagon hexagon-8 darker"></div>
            <div class="hexagon hexagon-9 light"></div>
            <div class="hexagon hexagon-10 light"></div>
            <div class="hexagon hexagon-11 light"></div>
            <div class="hexagon hexagon-12 light"></div>
            <div class="hexagon hexagon-13 light"></div>
            <div class="hexagon hexagon-14 light"></div>
            <div class="hexagon hexagon-15 light"></div>
        </div>

        <div class="row row-6"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
            <div class="hexagon hexagon-3 darker"></div>
            <div class="hexagon hexagon-4 darker"></div>
        </div>

        <div class="row row-7"
            [class.active]="animation">
            <div class="hexagon hexagon-1 darkest"></div>
            <div class="hexagon hexagon-2 darkest"></div>
        </div>
    </div>
</div>
