import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [{
    path: 'admin',
    loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
}, {
    path: 'app',
    loadChildren: () => import('./app-layout/app-layout.module').then(m => m.AppLayoutModule)
}, {
    path: 'redirect',
    loadChildren: () => import('./shared/components/redirect/redirect.module').then(m => m.RedirectModule)
}, {
    path: '',
    loadChildren: () => import('./public-layout/public-layout.module').then(m => m.PublicLayoutModule)
}, {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
}];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
