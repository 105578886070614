export class JwtClaims {
    user: number;
    admin = false;
    pruner = false;
    support = false;
    enterpriseBulkImport = false;
    enterpriseLeadMgmnt = false;

    constructor(claims: any) {
        if (claims) {
            if (claims.user) {
                this.user = claims.user;
            }

            this.admin  = !!(claims.admin);
            this.pruner = !!(claims.pruner);
            this.support = !!(claims.support);
            this.enterpriseBulkImport = !!(claims.enterpriseBulkImport);
            this.enterpriseLeadMgmnt = !!(claims.enterpriseLeadMgmnt);
        }
    }
}
