import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {HexagonsComponent} from './hexagons.component';

@NgModule({
    imports: [
        // SharedModule
    ],
    declarations: [HexagonsComponent],
    exports: [HexagonsComponent]
})
export class HexagonsModule {}
