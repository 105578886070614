import {throwError as observableThrowError, Observable} from 'rxjs';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {JwtService} from './services/jwt.service';
import {environment} from '../../environments/environment';
import {RoutingService} from './services/routing.service';
import {AuthService} from './services/auth.service';

export class ApiHttpInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthService,
        private _routingService: RoutingService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers: any = {
            Accept: 'application/json'
        };

        if (request && request.headers) {
            if (['application/pdf', 'application/vnd.api+json'].indexOf(request.headers.get('Accept')) !== -1) {
                headers.Accept = request.headers.get('Accept');
            }
        }

        const token = JwtService.raw();
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }

        request = request.clone({
            url: this.addXdebugParam(request.url),
            setHeaders: headers
        });

        return next.handle(request).pipe(
            catchError(
                (response: HttpErrorResponse) => {
                    if (response.status === 401) {
                        JwtService.clear();

                        return observableThrowError(response);
                    }

                    return observableThrowError(response);
                }
            )
        );
    }

    addXdebugParam(url: string) {
        if (environment.production) {
            return url;
        }

        return url + (url.indexOf('?') !== -1 ? '&' : '?') + 'XDEBUG_SESSION_START=PHPSTORM';
    }
}
