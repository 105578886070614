import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';

export function requiredIf(validationFunction: () => boolean): ValidatorFn {
    return (control: AbstractControl) => {
        if (validationFunction()) {
            return Validators.required(control);
        }

        return null;
    };
}
