import {Observable, throwError} from 'rxjs';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import {catchError, mergeMap} from 'rxjs/operators';
import {RoutingService} from './services/routing.service';
import {AuthService} from './services/auth.service';
import {ApiHttpInterceptor} from './api-http-interceptor';

export class ApiRefreshTokenInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthService,
        private _routingService: RoutingService,
        private _interceptor: ApiHttpInterceptor,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                const errorResponse = error as HttpErrorResponse;
                if (errorResponse.status === 401 &&
                    errorResponse.error &&
                    errorResponse.error.error &&
                    errorResponse.error.error === 'JWT Expired'
                ) {
                    return this._authService.refresh().pipe(mergeMap(() => {
                        return this._interceptor.intercept(request, next);
                    }));
                }

                return throwError(error);
            })
        );
    }
}
