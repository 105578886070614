import { environment } from '../environment';

export class Routes {

    static UI = {
        Index: '/',
        Login: '/login',
        Password: {
            Forgot: '/password/forgot',
            Reset: '/password/reset'
        },
        Pricing: '/pricing',
        Questions: '/questions',
        StateFilingRequirements: '/state-filing-information-for-nonprofits',
        Review: '/review',
        ReviewEZ: '/review-ez',
        ContactUs: '/contact-us',
        DoNotSellMyPersonalInformation: '/do-not-sell-my-personal-information',
        TermsOfUse: '/terms',
        PrivacyPolicy: '/privacy',
        CCPA: '/ccpa',

        App: {
            Billing: '/app/billing',
            Contacts: '/app/contacts',
            Filings: '/app/filings',
            Checkout: '/app/checkout',
            ResubmissionConfirmation: '/app/resubmission-confirmation',
            UploadForm990nData: '/app/upload-form990n-data',
            BulkImportBatches: '/app/bulk-import-batches',
            BulkImportBatch: (batchId: string) => {
                return Routes.UI.App.BulkImportBatches + '/' + batchId;
            },
            Receipt: (paymentAttemptId: string) => {
                return '/app/receipt/' + paymentAttemptId;
            },
            Settings: '/app/settings',

            Form990: (id: string) => {
                const path = '/app/form990/' + id;

                return Routes.Form990RouteMap(path);
            },

            Form990ez: (id: string) => {
                const path = '/app/form990ez/' + id;

                return Routes.Form990ezRouteMap(path);
            },

            Form990pf: (id: string) => {
                const path = '/app/form990pf/' + id;

                return Routes.Form990pfRouteMap(path);
            },

            StartForm990: (ein: number, year: number) => {
                return '/app/form990/' + ein + '/' + year;
            },

            StartForm990ez: (ein: number, year: number) => {
                return '/app/form990ez/' + ein + '/' + year;
            },

            StartForm990pf: (ein: number, year: number) => {
                return '/app/form990pf/' + ein + '/' + year;
            }
        },

        Admin: {
            Index: '/admin/',
            StateInfo: (stateKey: string) => {
                if (!stateKey) {
                    return '/admin/state-information';
                }

                return '/admin/state-information/' + stateKey;
            },
            Finder: (ein: number | string) => {
                return '/admin/lead-finder/' + ein.toString();
            },
            PendingSubmission: (id: number | string) => {
                return '/admin/pending/submission/' + id.toString();
            },
            PendingStatus: (id: number | string) => {
                return '/admin/pending/status/' + id.toString();
            },
            AlreadyFiledReview: (ein: number | string) => {
                return '/admin/already-filed-review/' + ein.toString();
            },
            GeneralReview: (ein: number | string) => {
                return '/admin/general-review/' + ein.toString();
            },
            LongFormPruner: (ein: number | string) => {
                return '/admin/long-form-pruner/' + ein.toString();
            },
            LongFormFinder: (ein: number | string) => {
                return '/admin/long-form-finder/' + ein.toString();
            },
            StateSearch: (ein: number | string, state: string) => {
                return '/admin/' + state + '/' + ein.toString();
            },
            UserBacklog: (ein: number | string) => {
                return '/admin/user-backlog/' + ein.toString();
            },
            OfficerReview: (ein: number | string) => {
                return '/admin/officer-review/' + ein.toString();
            },
            Removals: (ein: number | string) => {
                return '/admin/removals/' + ein.toString();
            },
            Undeliverable: (ein: number | string) => {
                return '/admin/undeliverable/' + ein.toString();
            },
            Unverifiable: (ein: number | string) => {
                return '/admin/unverifiable/' + ein.toString();
            },
            MultiEin: (email: string) => {
                return '/admin/multi-ein/' + email.toString();
            },
            MultiEinRemovals: (email: string) => {
                return '/admin/multi-ein/removals/' + email.toString();
            },
            EmailLeads: '/admin/email-leads',
            EmailLead: (email) => {
                return Routes.UI.Admin.EmailLeads + '/' + email;
            },
            Form990ns: '/admin/form990ns',
            Filings: '/admin/filings',
            MaintenanceWindows: '/admin/maintenance-windows',
            Organizations: '/admin/organizations',
            Organization: (organizationId) => {
                return Routes.UI.Admin.Organizations + '/' + organizationId;
            },
            Payments: '/admin/payments',
            SupportTickets: '/admin/support-tickets',
            EmailSupportTemplates: '/admin/support-email-templates',
            Imports: '/admin/imports',
            PricingOverrides: '/admin/pricing-overrides',
            Rejections: '/admin/rejections',
            ReminderSettings: '/admin/reminder-settings',
            Umbrellas: '/admin/umbrellas',
            PruningDashboard: '/admin/pruning-dashboard',
            NeedsReview: '/admin/needs-review',
            Umbrella: (umbrellaId) => {
                return Routes.UI.Admin.Umbrellas + '/' + umbrellaId;
            },
            Users: '/admin/users',
            User: (userId) => {
                return Routes.UI.Admin.Users + '/' + userId;
            },
        }
    };

    static API = {
        // Authentication forms.
        Auth: {
            Signup: environment.api + '/auth/register',
            Login: environment.api + '/auth/login',
            Refresh: environment.api + '/auth/refresh',

            PasswordResetRequest: environment.api + '/auth/password/email',
            PasswordReset: environment.api + '/auth/password/reset',
        },

        SupportTicket: (ticketId) => {
            return environment.jsonapi + '/support-tickets/' + ticketId;
        },
        SupportTickets: {
            AssociateByEin: (ticketId, ein) => {
                return this.API.SupportTicket(ticketId) + '/associate/ein/' + ein;
            },
            AssociateByUser: (ticketId, userId) => {
                return this.API.SupportTicket(ticketId) + '/associate/user/' + userId;
            }
        },

        // Unique endpoints.
        BillingMethod: environment.api + '/billing-method',
        BulkReviewDownload: environment.api + '/bulk-review-download',
        ContactUs: environment.api + '/contact-us',
        DoNotSellMyPersonalInformation: environment.api + '/do-not-sell-my-personal-information',
        Checkout: environment.api + '/checkout',

        EmailLeads: environment.api + '/email-leads',
        AuthorizeFiling: (ein, hash) => {
            return environment.api + '/authorize-filing/' + ein + '/' + hash;
        },
        AlreadyFiled: (ein, hash) => {
            return environment.api + '/already-filed/' + ein + '/' + hash;
        },

        // JSON:API models.
        Form8453eos: environment.jsonapi + '/form8453eos',
        Form8453eoDocument: (form8453eoId) => {
            return Routes.API.Form8453eos + '/' + form8453eoId + '/document';
        },
        Form8453eoResend: (form8453eoId) => {
            return Routes.API.Form8453eos + '/' + form8453eoId + '/resend';
        },
        Form8879eos: environment.jsonapi + '/form8879eos',
        Form8879eoDocument: (form8879eoId) => {
            return Routes.API.Form8879eos + '/' + form8879eoId + '/document';
        },
        Form990ns: environment.jsonapi + '/form990ns',
        Form990n: (form990nId) => {
            return Routes.API.Form990ns + '/' + form990nId;
        },
        Form990nsByEinTaxYear: () => {
            return Routes.API.Form990ns + '/eins/years';
        },
        Form990MarkReviewed: environment.api + '/form990s/reviewed',
        Form990ezs: environment.jsonapi + '/form990ezs',
        Form990ez: (form990ezId) => {
            return Routes.API.Form990ezs + '/' + form990ezId;
        },
        Form990ezAttachments: (form990ezId) => {
            return environment.api + '/form990ezs/' + form990ezId + '/attachments';
        },
        Form990ezForYear: (ein: string, year: string) => {
            return Routes.API.Form990ezs + '/' + ein + '/' + year;
        },
        Form990ezMarkReviewed: environment.api + '/form990ezs/reviewed',
        Form990pfs: environment.jsonapi + '/form990pfs',
        Form990pf: (form990pfId) => {
            return Routes.API.Form990pfs + '/' + form990pfId;
        },
        Form990pfAttachments: (form990pfId) => {
            return environment.api + '/form990pfs/' + form990pfId + '/attachments';
        },
        Form990pfForYear: (ein: string, year: string) => {
            return Routes.API.Form990pfs + '/' + ein + '/' + year;
        },
        TaxExemptReturnSubmissions: environment.jsonapi + '/tax-exempt-return-submissions',
        TaxExemptReturnSubmission: (submissionId) => {
            return Routes.API.TaxExemptReturnSubmissions + '/' + submissionId;
        },
        Payments: environment.jsonapi + '/payments',
        Payment: (paymentId) => {
            return Routes.API.Payments + '/' + paymentId;
        },
        Form990s: environment.jsonapi + '/form990s',
        Form990: (form990Id) => {
            return Routes.API.Form990s + '/' + form990Id;
        },
        Form990Attachments: (form990Id) => {
            return environment.api + '/form990s/' + form990Id + '/attachments';
        },
        Form990ForYear: (ein: string, year: string) => {
            return Routes.API.Form990s + '/' + ein + '/' + year;
        },
        Form990NeedsReview: () => {
            return Routes.API.Form990s + '/needs-review';
        },

        NBulkImportBatches: environment.api + '/n-bulk-import-batches',
        NBulkImportBatch: (batchId) => {
            return Routes.API.NBulkImportBatches + '/' + batchId;
        },

        RevenueReports: () => {
            return {
                Summary: environment.api + '/revenue-reports/summary'
            };
        },

        ReviewEins: () => {
            return Routes.API.EmailLeads + '/review-eins';
        },
        ReviewEmails: () => {
            return Routes.API.EmailLeads + '/review-emails';
        },
        Users: environment.jsonapi + '/users',
        User: (userId) => {
            return Routes.API.Users + '/' + userId;
        },
        UserForms: (userId) => {
            return Routes.API.User(userId) + '/forms';
        },
        Organizations: environment.jsonapi + '/organizations',
        Organization: (ein) => {
            return Routes.API.Organizations + '/' + ein;
        },
        Forms: () => {
            return Routes.API.Organizations + '/forms';
        },
        OrganizationForms: (ein) => {
            return Routes.API.Organization(ein) + '/forms';
        }
    };

    public static Form990RouteMap(path: string) {
        return {
            General: path + '/general',
            Part1: path + '/part/1',
            Part2: path + '/part/2',
            Part3: path + '/part/3',
            Part4: path + '/part/4',
            Part5: path + '/part/5',
            Part6: path + '/part/6',
            Part7: path + '/part/7',
            Part8: path + '/part/8',
            Part9: path + '/part/9',
            Part10: path + '/part/10',
            Part11: path + '/part/11',
            Part12: path + '/part/12',
            ScheduleA: {
                Part1: path + '/schedules/a/part/1',
                Part2: path + '/schedules/a/part/2',
                Part3: path + '/schedules/a/part/3',
                Part4: path + '/schedules/a/part/4',
                Part5: path + '/schedules/a/part/5',
                Part6: path + '/schedules/a/part/6',
            },
            ScheduleB: {
                Part0: path + '/schedules/b/part/0',
                Part1: path + '/schedules/b/part/1',
                Part2: path + '/schedules/b/part/2',
                Part3: path + '/schedules/b/part/3',
            },
            ScheduleC: {
                Part0: path + '/schedules/c/part/0',
                Part2a: path + '/schedules/c/part/2a',
                Part2b: path + '/schedules/c/part/2b',
                Part3a: path + '/schedules/c/part/3a',
                Part3b: path + '/schedules/c/part/3b',
                Part4: path + '/schedules/c/part/4',
            },
            ScheduleD: {
                Part1: path + '/schedules/d/part/1',
                Part2: path + '/schedules/d/part/2',
                Part3: path + '/schedules/d/part/3',
                Part4: path + '/schedules/d/part/4',
                Part5: path + '/schedules/d/part/5',
                Part6: path + '/schedules/d/part/6',
                Part7: path + '/schedules/d/part/7',
                Part8: path + '/schedules/d/part/8',
                Part9: path + '/schedules/d/part/9',
                Part10: path + '/schedules/d/part/10',
                Part11: path + '/schedules/d/part/11',
                Part12: path + '/schedules/d/part/12',
                Part13: path + '/schedules/d/part/13',
            },
            ScheduleE: {
                Part1: path + '/schedules/e/part/1',
                Part2: path + '/schedules/e/part/2',
            },
            ScheduleF: {
                Part1: path + '/schedules/f/part/1',
                Part2: path + '/schedules/f/part/2',
                Part3: path + '/schedules/f/part/3',
                Part4: path + '/schedules/f/part/4',
                Part5: path + '/schedules/f/part/5',
            },
            ScheduleG: {
                Part1: path + '/schedules/g/part/1',
                Part2: path + '/schedules/g/part/2',
                Part3: path + '/schedules/g/part/3',
                Part4: path + '/schedules/g/part/4',
            },
            ScheduleH: {
                Part1: path + '/schedules/h/part/1',
                Part2: path + '/schedules/h/part/2',
                Part3: path + '/schedules/h/part/3',
                Part4: path + '/schedules/h/part/4',
                Part5a: path + '/schedules/h/part/5a',
                Part5b: path + '/schedules/h/part/5b',
                Part5c: path + '/schedules/h/part/5c',
                Part5d: path + '/schedules/h/part/5d',
                Part6: path + '/schedules/h/part/6',
                Part7: path + '/schedules/h/part/7',
            },
            ScheduleI: {
                Part1: path + '/schedules/i/part/1',
                Part2: path + '/schedules/i/part/2',
                Part3: path + '/schedules/i/part/3',
                Part4: path + '/schedules/i/part/4',
            },
            ScheduleJ: {
                Part1: path + '/schedules/j/part/1',
                Part2: path + '/schedules/j/part/2',
                Part3: path + '/schedules/j/part/3',
            },
            ScheduleK: {
                Part1: path + '/schedules/k/part/1',
                Part2: path + '/schedules/k/part/2',
                Part3: path + '/schedules/k/part/3',
                Part4: path + '/schedules/k/part/4',
                Part5: path + '/schedules/k/part/5',
                Part6: path + '/schedules/k/part/6',
            },
            ScheduleL: {
                Part1: path + '/schedules/l/part/1',
                Part2: path + '/schedules/l/part/2',
                Part3: path + '/schedules/l/part/3',
                Part4: path + '/schedules/l/part/4',
                Part5: path + '/schedules/l/part/5',
            },
            ScheduleM: {
                Part1: path + '/schedules/m/part/1',
                Part2: path + '/schedules/m/part/2',
            },
            ScheduleN: {
                Part1: path + '/schedules/n/part/1',
                Part2: path + '/schedules/n/part/2',
                Part3: path + '/schedules/n/part/3',
            },
            ScheduleO: path + '/schedules/o',
            ScheduleR: {
                Part1: path + '/schedules/r/part/1',
                Part2: path + '/schedules/r/part/2',
                Part3: path + '/schedules/r/part/3',
                Part4: path + '/schedules/r/part/4',
                Part5: path + '/schedules/r/part/5',
                Part6: path + '/schedules/r/part/6',
                Part7: path + '/schedules/r/part/7',
            }
        };
    }

    public static Form990ezRouteMap(path: string) {
        return {
            General: path + '/general',
            Part1: path + '/part/1',
            Part2: path + '/part/2',
            Part3: path + '/part/3',
            Part4: path + '/part/4',
            Part5: path + '/part/5',
            Part6: path + '/part/6',
            Signature: path + '/signature',
            ScheduleA: {
                Part1: path + '/schedules/a/part/1',
                Part2: path + '/schedules/a/part/2',
                Part3: path + '/schedules/a/part/3',
                Part4: path + '/schedules/a/part/4',
                Part5: path + '/schedules/a/part/5',
                Part6: path + '/schedules/a/part/6',
            },
            ScheduleB: {
                Part0: path + '/schedules/b/part/0',
                Part1: path + '/schedules/b/part/1',
                Part2: path + '/schedules/b/part/2',
                Part3: path + '/schedules/b/part/3',
            },
            ScheduleC: {
                Part0: path + '/schedules/c/part/0',
                Part2a: path + '/schedules/c/part/2a',
                Part2b: path + '/schedules/c/part/2b',
                Part3a: path + '/schedules/c/part/3a',
                Part3b: path + '/schedules/c/part/3b',
                Part4: path + '/schedules/c/part/4',
            },
            ScheduleE: {
                Part1: path + '/schedules/e/part/1',
                Part2: path + '/schedules/e/part/2',
            },
            ScheduleG: {
                Part1: path + '/schedules/g/part/1',
                Part2: path + '/schedules/g/part/2',
                Part3: path + '/schedules/g/part/3',
                Part4: path + '/schedules/g/part/4',
            },
            ScheduleL: {
                Part1: path + '/schedules/l/part/1',
                Part2: path + '/schedules/l/part/2',
                Part3: path + '/schedules/l/part/3',
                Part4: path + '/schedules/l/part/4',
                Part5: path + '/schedules/l/part/5',
            },
            ScheduleN: {
                Part1: path + '/schedules/n/part/1',
                Part2: path + '/schedules/n/part/2',
                Part3: path + '/schedules/n/part/3',
            },
            ScheduleO: path + '/schedules/o'
        };
    }

    public static Form990pfRouteMap(path: string) {
        return {
            General: path + '/general',
            Part1: path + '/part/1',
            Part2: path + '/part/2',
            Part3: path + '/part/3',
            Part4: path + '/part/4',
            Part5: path + '/part/5',
            Part6a: path + '/part/6a',
            Part6b: path + '/part/6b',
            Part7: path + '/part/7',
            Part8a: path + '/part/8a',
            Part8b: path + '/part/8b',
            Part9: path + '/part/9',
            Part10: path + '/part/10',
            Part11: path + '/part/11',
            Part12: path + '/part/12',
            Part13: path + '/part/13',
            Part14: path + '/part/14',
            Part15a: path + '/part/15a',
            Part15b: path + '/part/15b',
            Part16: path + '/part/16',
            Signature: path + '/signature',
            ScheduleB: {
                Part0: path + '/schedules/b/part/0',
                Part1: path + '/schedules/b/part/1',
                Part2: path + '/schedules/b/part/2',
                Part3: path + '/schedules/b/part/3',
            },
            ScheduleO: path + '/schedules/o'
        };
    }
}
