import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Routes} from '../../../environments/vars/routes';
import {Observable, ReplaySubject} from 'rxjs';
import {JwtService} from './jwt.service';
import {LocalStorageService} from './local-storage.service';
import * as moment from 'moment-timezone';

export class ArrivalBinding {
    ein: number;
    email: string;

    constructor(binding: any) {
        this.ein = binding.ein || null;
        this.email = binding.email || null;
    }
}

export class RefreshToken {
    refresh_token: string;
    access_token: string;
    client_skew: number;
}

export class SignupRequest {
    site_id: number;
    email: string;
    password: string;
    agreedTerms: boolean;
    client_time: number;

    constructor(request: any) {
        this.site_id     = request.site_id || '';
        this.email       = request.email || '';
        this.password    = request.password || '';
        this.agreedTerms = request.agreedTerms;
    }
}

export class LoginRequest {
    email: string;
    password: string;
    redirectUri?: string;
    client_time: number;

    constructor(request: any) {
        this.email = request.email || null;
        this.password = request.password || null;
        this.redirectUri = request.redirectUri || null;
    }
}

export class ResetPasswordRequest {
    email: string;
    password: string;
    password_confirmation: string;
    reset_token: string;
    client_time: number;

    constructor(request: any) {
        this.email = request.email || '';
        this.password = request.password || '';
        this.password_confirmation = request.password_confirmation || '';
        this.reset_token = request.reset_token || '';
    }
}

@Injectable()
export class AuthService {

    reauthenticationNeeded: EventEmitter<boolean> = new EventEmitter<boolean>();
    loggedIn: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private _router: Router,
        private _http: HttpClient
    ) {}

    autologin(token: string): Observable<RefreshToken> {
        return this._http.post<RefreshToken>(
            Routes.API.Auth.Login,
            JSON.stringify({auto: token, client_time: moment.utc().valueOf()}),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    billingMethod(): Observable<any> {
        return this._http.get<any>(
            Routes.API.BillingMethod,
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    saveBillingMethod(token: string): Observable<any> {
        const requestBody = {
            cardToken: token
        };
        return this._http.put<any>(
            Routes.API.BillingMethod,
            requestBody,
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    removeBillingMethod(): Observable<any> {
        return this._http.delete<any>(
            Routes.API.BillingMethod,
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    login(loginRequest: LoginRequest): Observable<RefreshToken> {
        loginRequest.client_time = moment.utc().valueOf();
        return this._http.post<RefreshToken>(
            Routes.API.Auth.Login,
            JSON.stringify(loginRequest),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    signup(signupRequest: SignupRequest): Observable<RefreshToken> {
        signupRequest.client_time = moment.utc().valueOf();
        return this._http.post<RefreshToken>(
            Routes.API.Auth.Signup,
            JSON.stringify(signupRequest),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    manualRefresh(token: string): Promise<RefreshToken> {
        return this._http.post<RefreshToken>(
            Routes.API.Auth.Refresh,
            JSON.stringify({token, client_time: moment.utc().valueOf()}),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        ).toPromise();
    }

    refresh(): ReplaySubject<RefreshToken> {
        const refreshToken = JwtService.getRefreshToken();
        if (!refreshToken) {
            JwtService.clear();
            JwtService.clearRefreshToken();
            this.triggerReauthentication();
        }

        const body = {token: refreshToken, client_time: moment.utc().valueOf()};
        const refreshObservable = this._http.post<RefreshToken>(
            Routes.API.Auth.Refresh,
            JSON.stringify(body),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
        const refreshSubject = new ReplaySubject<RefreshToken>(1);

        refreshSubject.subscribe((response: RefreshToken) => {
            JwtService.store(response.access_token);
            JwtService.storeRefreshToken(response.refresh_token);
            JwtService.storeClientSkew(response.client_skew);

            return response;
        }, () => {
            JwtService.clear();
            JwtService.clearRefreshToken();

            this.triggerReauthentication();

            refreshSubject.complete();
            return;
        });

        refreshObservable.subscribe(refreshSubject);

        return refreshSubject;
    }

    sendForgotPasswordEmail(request: any): Observable<object> {
        return this._http.post(
            Routes.API.Auth.PasswordResetRequest,
            JSON.stringify(request),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<object> {
        resetPasswordRequest.client_time = moment.utc().valueOf();
        return this._http.post(
            Routes.API.Auth.PasswordReset,
            JSON.stringify(resetPasswordRequest),
            {headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    triggerReauthentication() {
        this.reauthenticationNeeded.emit(true);
    }

    getArrivalBinding(): ArrivalBinding {
        const bindingJson = LocalStorageService.get('binding');
        if (bindingJson === null) {
            return null;
        }

        return new ArrivalBinding(JSON.parse(bindingJson));
    }

    setArrivalBinding(binding: ArrivalBinding) {
        LocalStorageService.set('binding', JSON.stringify(binding));
    }
}
