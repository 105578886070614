import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {JwtService} from '../services/jwt.service';
import * as Sentry from '@sentry/angular';
import {ErrorHandlerOptions} from '@sentry/angular';
import {runOutsideAngular} from '@sentry/angular/dist/zone';

@Injectable({ providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
    protected readonly _options: ErrorHandlerOptions;

    public constructor(@Inject('ErrorHandlerOptions') options?: ErrorHandlerOptions) {
        this._options = {
            logErrors: true,
            ...options,
        };
    }

    public handleError(error): void {
        const extractedError = this._extractError(error) || 'Handled unknown error';

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test((extractedError || '').toString())) {
            window.location.reload();
            return;
        }

        Sentry.configureScope((scope) => {
            const claims = JwtService.getCustomClaims();
            if (claims && claims.user) {
                scope.setUser({
                    id: claims.user.toString()
                });
            }
            else {
                scope.setUser(null);
            }
        });

        // Capture handled exception and send it to Sentry.
        const eventId = runOutsideAngular(() => Sentry.captureException(extractedError));

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            if (this._options.logErrors) {
                // eslint-disable-next-line no-console
                console.error(extractedError);
            }

            // When in development mode, log the error to console for immediate feedback.
            if (this._options.showDialog) {
                // Optionally show user dialog to provide details on what happened.
                Sentry.showReportDialog({ ...this._options.dialogOptions, eventId });
            }
        }
    }

    /**
     * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
     */
    protected _extractError(error: unknown): unknown {
        // Allow custom overrides of extracting function
        if (this._options.extractor) {
            const defaultExtractor = this._defaultExtractor.bind(this);
            return this._options.extractor(error, defaultExtractor);
        }

        return this._defaultExtractor(error);
    }

    /**
     * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
     */
    protected _defaultExtractor(errorCandidate: unknown): unknown {
        let error = errorCandidate;

        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
            error = (error as { ngOriginalError: Error }).ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent && error.error.message) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Nothing was extracted, fallback to default error message.
        return null;
    }
}
