import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

    static get(key: string): string {
        return localStorage.getItem(key);
    }

    static set(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    static destroy(key: string) {
        return localStorage.removeItem(key);
    }
}
