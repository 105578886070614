import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-hexagons',
    templateUrl: './hexagons.component.html',
    styleUrls: ['./hexagons.component.scss']
})

export class HexagonsComponent implements OnInit {

    public animation: any = false;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.animation = true;
        }, 100);
    }
}
