export const environment = {
    production: true,
    envName: 'staging',
    siteId: 1,
    api: 'https://api.staging.simple990.com',
    jsonapi: 'https://api.staging.simple990.com/v2',
    name: 'Simple 990',
    merchant: 'square',

    google: {
        gtm: 'GTM-N8MQZNJ'
    },
    square: {
        applicationId: 'sandbox-sq0idb-EeSXpieqWDdKaaff9Yq_Tg',
        locationId: 'LAGN5W5S53HSX'
    },
    stripe: {
        publicKey: 'pk_test_9fZBcV9DPUKP8Yw92EdvaTDd',
        clientId: 'ca_CY5qPssieFzetwu7NlO8Fd8jmLl3IzJ5'
    },
    s3: {
        bucket: '990n-dev'
    },
    sentry: {
        dsn: 'https://76d036d6497f4ee2872cf7797c4caa00@sentry.io/1863983'
    }
};
