import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {RoutingService} from '../services/routing.service';
import {JwtService} from '../services/jwt.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class EmployeeGuard implements CanActivate, CanActivateChild {

    constructor(
        private _authService: AuthService,
        private _routingService: RoutingService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAllowed();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.isAllowed();
    }

    private async isAllowed(): Promise<boolean> {
        const claims = JwtService.getCustomClaims();
        if (claims !== null) {
            return claims.admin || claims.support || claims.pruner;
        }

        const refreshToken = JwtService.getRefreshToken();
        if (refreshToken) {
            try {
                const token = await this._authService.manualRefresh(refreshToken);
                JwtService.storeClientSkew(token.client_skew);
                JwtService.storeRefreshToken(token.refresh_token);
                JwtService.store(token.access_token);

                const updatedClaims = JwtService.getCustomClaims();
                if (updatedClaims !== null) {
                    return updatedClaims.admin || updatedClaims.support || updatedClaims.pruner;
                }
            } catch (e) {
                this._authService.triggerReauthentication();
                return false;
            }
            this._authService.triggerReauthentication();
            return false;
        }

        this._authService.triggerReauthentication();
        return false;
    }
}
