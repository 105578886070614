import {Injectable} from '@angular/core';

@Injectable()
export class Base64Service {
    private static _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    //noinspection JSUnusedGlobalSymbols
    static encode(input: string): string {
        let output = '';
        let chr1: number;
        let chr2: number;
        let chr3: number;
        let enc1: number;
        let enc2: number;
        let enc3: number;
        let enc4: number;
        let i = 0;

        input = Base64Service.utf8_encode(input);

        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                Base64Service._keyStr.charAt(enc1) + Base64Service._keyStr.charAt(enc2) +
                Base64Service._keyStr.charAt(enc3) + Base64Service._keyStr.charAt(enc4);
        }

        return output;
    }

    static decode(input: string): string {
        let output = '';
        let chr1: number;
        let chr2: number;
        let chr3: number;
        let enc1: number;
        let enc2: number;
        let enc3: number;
        let enc4: number;
        let i = 0;

        input = input.replace(/[^A-Za-z0-9+\/=]/g, '');

        while (i < input.length) {
            enc1 = Base64Service._keyStr.indexOf(input.charAt(i++));
            enc2 = Base64Service._keyStr.indexOf(input.charAt(i++));
            enc3 = Base64Service._keyStr.indexOf(input.charAt(i++));
            enc4 = Base64Service._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output = output + String.fromCharCode(chr3);
            }

        }
        output = Base64Service.utf8_decode(output);

        return output;
    }

    static utf8_encode(unencodedText: string): string {
        unencodedText = unencodedText.replace(/\r\n/g, '\n');
        let encodedText = '';

        for (let n = 0; n < unencodedText.length; n++) {
            const c: number = unencodedText.charCodeAt(n);

            if (c < 128) {
                encodedText += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                encodedText += String.fromCharCode((c >> 6) | 192);
                encodedText += String.fromCharCode((c & 63) | 128);
            } else {
                encodedText += String.fromCharCode((c >> 12) | 224);
                encodedText += String.fromCharCode(((c >> 6) & 63) | 128);
                encodedText += String.fromCharCode((c & 63) | 128);
            }
        }

        return encodedText;
    }

    static utf8_decode(encodedText: string): string {
        let decodedText = '';
        let i = 0;
        let c = 0;
        let c2 = 0;

        while (i < encodedText.length) {
            c = encodedText.charCodeAt(i);

            if (c < 128) {
                decodedText += String.fromCharCode(c);
                i++;
            } else if ((c > 191) && (c < 224)) {
                c2 = encodedText.charCodeAt(i + 1);
                decodedText += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = encodedText.charCodeAt(i + 1);
                const c3: number = encodedText.charCodeAt(i + 2);
                decodedText += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }

        return decodedText;
    }
}
